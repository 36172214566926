import React, { useContext, useState, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";
import { AutomationApi, GiftcardApi } from "../../apiclient";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import {
  Automation,
  AutomationAccountPlatformProductIds,
  AutomationAwardAmountType,
  AutomationAwardIssuedTo,
  AutomationAwardType,
  AutomationOrderTriggerConditionType,
  AutomationTriggerType,
  SalesChannelListItemViewModel,
  AutomationGiftCardExpiryType
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import {
  Link,
  useSearchParams,
  useNavigate,
  useParams,
} from "react-router-dom";

import Util from "../../js/util";
import moment from "moment";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));
import * as Swal from "sweetalert2";

const AutomationEditPage = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  let automationApi = new AutomationApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  let giftcardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const authContext = useContext(AuthContext);
  const [model, setModel] = useState({} as Automation);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [isLoading, setLoading] = useState(false as boolean);
  const [startsAt, setStartsAt] = useState("");
  const [endsAt, setEndsAt] = useState("");
  const [salesChannels, setSalesChannels] = useState(
    [] as SelectOption[]
  );

  interface SelectOption {
    value: string;
    label: string;
  }

  useEffect(() => {
    loadAutomation();
    fetchSalesChannels();
  }, []);

  const fetchSalesChannels = () => {
    giftcardApi
      .giftcardSalesChannelListGet()
      .then((response) => {
        var data = response.data as SalesChannelListItemViewModel[];
        const options = data.map(item => ({
          value: item.id,
          label: `${item.channelName} (${item.platform})`
        } as SelectOption));

        setSalesChannels(options);
      })
      .catch((error: any) => {
        console.log("get error", error);
      });
  };

  const loadAutomation = () => {
    automationApi.automationGetAutomationByIdIdGet(id!).then((response) => {
      var data = response.data;
      if (data.endsAt) {
        const csharpDate = moment(data.endsAt);
        const formattedDateTime = csharpDate.format("YYYY-MM-DD HH:mm");
        setEndsAt(formattedDateTime);
      }

      if (data.startsAt) {
        const csharpDate = moment(data.startsAt);
        const formattedDateTime = csharpDate.format("YYYY-MM-DD HH:mm");
        setStartsAt(formattedDateTime);
      }
      setModel(data);
      setLoading(false);
    });
  }

  const handleSubmitAutomationUpdate = (event: any) => {
    //setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    // }

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      if (!model.startsAt) {
        model.startsAt = null;
      }

      if (!model.endsAt) {
        model.endsAt = null;
      }
      automationApi
        .automationUpdateAutomationPut("1", model)
        .then((response) => {
          toast.success("Automation saved");
          loadAutomation();
        })
        .catch((error: any) => {
          console.log("post error", error);
          toast.error("Error updating automation");
        });
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    if (name === "endsAt") {
      console.log("endAt", value);
      setEndsAt(value);
    }

    if (name === "startsAt") {
      setStartsAt(value);
    }
    setModel((values: Automation) => ({
      ...values,
      [name]: value,
    }));
  };

  const getStatusBadge = () => {
    var cardStatus = model.status;
    var badgeColor = "bg-secondary text-dark";
    if (cardStatus == "Active") {
      badgeColor = "bg-success text-success";
    }
    return (<span
      className={`badge text-uppercase bg-opacity-30 ${badgeColor}`}
    >
      {cardStatus}
    </span>)
  }

  const disableAutomation = () => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `This will disable the automation. Note: this will not delete any generated gift cards or credits.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, disable automation",

        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          automationApi.automationDisableAutomationIdPut(model.id!)
            .then((response) => {
              var data = response.data;
              if (data) {
                toast.success("Automation disabled.");
                loadAutomation();
              } else {
                toast.error("Couldn't disable automation.");
              }
            })
            .catch((error: any) => {
              toast.error("Couldn't disable automation.");
            });
        }
      });
  }

  const saveAndNavigateToEditEmail = (e: any) => {
    e.preventDefault();
    automationApi
      .automationUpdateAutomationPut("1", model)
      .then((response) => {
        toast.success("Automation saved.");
        navigate(`/automations/design-email/${id}`);
      })
      .catch((error: any) => {
        console.log("post error", error);
        toast.error("Error updating automation");
      });

  }


  const activateAutomation = () => {
    Swal.default
      .fire({
        title: "Activate automation?",
        text: `This will activate the automation`,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, activate automation",
      })
      .then((result) => {
        if (result.isConfirmed) {
          automationApi
            .automationUpdateAutomationPut("1", model)
            .then((response) => {
              automationApi.automationEnableAutomationIdPut(model.id!)
                .then((response) => {
                  var data = response.data;
                  if (data) {
                    toast.success("Automation activated.");
                    //loadAutomation();
                    navigate(`/automations`);
                  } else {
                    toast.error("Couldn't activate automation.");
                  }
                })
                .catch((error: any) => {
                  toast.error("Couldn't activate automation.");
                });
            })
            .catch((error: any) => {
              console.log("post error", error);
              toast.error("Error updating automation");
            });


        }
      });
  }

  const getStatusButton = () => {
    if (model.status == "Active") {
      return (<button type="button" onClick={disableAutomation} className="btn btn-danger btn-sm ">Disable</button>);
    }
    else if (model.status == "Disabled") {
      return (<button type="button" onClick={activateAutomation} className="btn btn-primary btn-sm ">Enable</button>);
    }
    else if (model.status == "Draft") {
      return (<button type="button" onClick={activateAutomation} className="btn btn-primary btn-sm ">Activate</button>);
    }
  }

  let handleChannelChange = (option: SelectOption | null, i: number) => {
    let newProductChannels = [...model.triggerProductIds!] as any;
    newProductChannels[i]["accountPlatformId"] = option?.value;

    setModel((values: Automation) => ({
      ...values,
      triggerProductIds: newProductChannels,
    }));
  };

  let handleProductIdsChange = (options: any | null, i: number) => {
    console.log("options", options);
    let newProductChannels = [...model.triggerProductIds!] as any;
    const values = options ? options.map((option: { value: any; }) => option.value) : [];
    newProductChannels[i]["productSourceIds"] = values;

    setModel((values: Automation) => ({
      ...values,
      triggerProductIds: newProductChannels,
    }));
  };

  let addProductChannel = () => {
    let newProductChannels = [...model.triggerProductIds!] as AutomationAccountPlatformProductIds[];
    newProductChannels.push({
      id: Util.generateRandomString(16),
      accountPlatformId: "",
      productSourceIds: [],
    });
    setModel((values: Automation) => ({
      ...values,
      triggerProductIds: newProductChannels,
    }));
  };

  let deleteProductChannel = (i: number) => {
    let newProductChannels = [...model.triggerProductIds!] as any;
    newProductChannels.splice(i, 1);
    setModel((values: Automation) => ({
      ...values,
      triggerProductIds: newProductChannels,
    }));
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <Spinner animation="border" />
        </>
      ) : (
        <>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmitAutomationUpdate}
          >
            <header className="position-sticky top-0 overlap-10 bg-surface-primary border-bottom">
              <div className="container-fluid py-4">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="d-flex align-items-center gap-4">
                      <div>
                        <Link
                          className="bi bi-chevron-left text-xs"
                          to={`/automations`}
                        ></Link>
                      </div>
                      <div className="vr opacity-20 my-1"></div>
                      <h1 className="h4 ls-tight">Update automation</h1>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="hstack gap-2 justify-content-end">
                      {/* <a
                      href="https://help.wrappedgiftcard.com/en/collections/3830625-account#general"
                      target={"_blank"}
                      className="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block"
                    >
                      <i className="bi bi-question-circle-fill"></i>
                      <span className="d-none d-sm-inline ms-2">
                        Need help?
                      </span>
                    </a> */}
                      {model && <>
                        Status: {getStatusBadge()}
                      </>}
                      <button type="submit" className="btn btn-sm btn-neutral">
                        <span>Save changes</span>
                      </button>
                      {getStatusButton()}
                    </div>
                  </div>
                </div>
              </div>
            </header>

            <div className="bg-surface-secondary">
              <div className="container py-10">
                <div className="row mb-10 mt-0">
                  <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                    <h4 className="font-semibold mb-2">Name your automation</h4>
                    <p className="text-sm">
                      Name your promotion so you can understand it easily in the
                      future.
                    </p>
                  </div>
                  <div className="col-lg-8">
                    <div className="card shadow border-0">
                      <div className="card-body">
                        <div className="row g-5">
                          <div className="col-md-12">
                            <div className="">
                              <label className="form-label" htmlFor="name">
                                Name
                              </label>
                              <p className="text-sm text-muted d-none d-lg-block pb-5">
                                Not visible to the customer
                              </p>
                              <Form.Control
                                name={"name"}
                                className="form-control"
                                type="text"
                                value={model.name}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-10 mt-0">
                  <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                    <h4 className="font-semibold mb-2">
                      Triggering conditions
                    </h4>
                    <p className="text-sm">
                      All conditions must be met for this automation to trigger
                    </p>
                  </div>
                  <div className="col-lg-8">
                    <div className="card shadow border-0">
                      <div className="card-body">
                        <div className="row g-5">
                          <div className="col-md-12">
                            <div className="">
                              <label
                                className="form-label"
                                htmlFor="triggerTypegiftCardPurchased"
                              >
                                Trigger type
                              </label>

                              <div className="d-flex">
                                <div className="me-3">
                                  <Form.Check
                                    name="triggerType"
                                    type={"radio"}
                                    id={`triggerTypeOrderPaid`}
                                    checked={
                                      model.triggerType ===
                                      AutomationTriggerType.OrderPaid
                                    }
                                    onChange={handleChange}
                                    value={AutomationTriggerType.OrderPaid}
                                  />
                                </div>
                                <div>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="triggerTypeOrderPaid"
                                  >
                                    Order paid
                                  </label>
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="me-3">
                                  <Form.Check
                                    name="triggerType"
                                    type={"radio"}
                                    id={`triggerTypeGiftCardPurchased`}
                                    checked={
                                      model.triggerType ===
                                      AutomationTriggerType.GiftCardPurchased
                                    }
                                    onChange={handleChange}
                                    value={AutomationTriggerType.GiftCardPurchased}
                                  />
                                </div>
                                <div>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="triggerTypeGiftCardPurchased"
                                  >
                                    Gift card purchased
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr className="my-6" />
                          <div className="col-md-12 mt-0">
                            <div className="d-flex ">
                              <div className="me-3">
                                <Form.Check
                                  name="shouldTriggerOnMinimumThreshold"
                                  id="shouldTriggerOnMinimumThreshold"
                                  type={"checkbox"}
                                  checked={
                                    model.shouldTriggerOnMinimumThreshold
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                              <div>

                                {model.triggerType == AutomationTriggerType.OrderPaid && <>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="shouldTriggerOnMinimumThreshold"
                                  >
                                    Require a minimum order spend?
                                  </label>
                                </>}

                                {model.triggerType == AutomationTriggerType.GiftCardPurchased && <>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="shouldTriggerOnMinimumThreshold"
                                  >
                                    Require a minimum gift card purchase amount?
                                  </label>
                                </>}

                              </div>
                            </div>
                            {model.shouldTriggerOnMinimumThreshold && (
                              <div className="">
                                <p className="text-sm text-muted d-none d-lg-block pb-5">
                                  Must spend at least this much{" "}
                                  {authContext.account?.isoCurrencyCode} to
                                  trigger this automation
                                </p>
                                <div className="col-md-4">
                                  <div className="input-group input-group-inline">
                                    <span className="input-group-text" id="">
                                      $
                                    </span>
                                    <Form.Control
                                      name={"minimumTriggerAmount"}
                                      className="form-control"
                                      type="number"
                                      value={model.minimumTriggerAmount}
                                      onChange={handleChange}
                                      placeholder="0.00"
                                      aria-label="0.00"
                                      required
                                    />
                                    <span className="input-group-text" id="">
                                      {authContext.account?.isoCurrencyCode}
                                    </span>
                                  </div>
                                  <span className="mt-2 valid-feedback">
                                    Looks good!
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <hr className="my-6" />
                          <div className="col-md-12 mt-0">
                            <div className="d-flex ">
                              <div className="me-3">
                                <Form.Check
                                  name="shouldExcludeOrdersWithGiftCardRedemptions"
                                  id="shouldExcludeOrdersWithGiftCardRedemptions"
                                  type={"checkbox"}
                                  checked={
                                    model.shouldExcludeOrdersWithGiftCardRedemptions
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                              <div>
                                <label
                                  className="form-label mb-0"
                                  htmlFor="shouldExcludeOrdersWithGiftCardRedemptions"
                                >
                                  Exclude orders with gift card redemptions?
                                </label>
                              </div>
                            </div>
                          </div>
                          {model.triggerType == AutomationTriggerType.OrderPaid && <>
                            <hr className="my-6" />
                            <div className="col-md-12 mt-0">
                              <div className="d-flex ">
                                <div className="me-3">
                                  <Form.Check
                                    name="shouldExcludeOrdersWithGiftCardPurchases"
                                    id="shouldExcludeOrdersWithGiftCardPurchases"
                                    type={"checkbox"}
                                    checked={
                                      model.shouldExcludeOrdersWithGiftCardPurchases
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="shouldExcludeOrdersWithGiftCardPurchases"
                                  >
                                    Exclude orders with gift card purchases?
                                  </label>
                                </div>
                              </div>
                            </div>
                          </>}
                          {model.triggerType == AutomationTriggerType.OrderPaid && <>
                            <hr className="my-6" />
                            <div className="col-12 mt-0">
                              <div className="mb-1">
                                <label className="form-label">
                                  Only give credit from specific products
                                </label>
                              </div>

                              {model &&
                                model.triggerProductIds?.map((productChannel, i) => (
                                  <div
                                    key={"productChannel" + i}
                                    className="row g-5 pb-5"
                                  >
                                    <div className="col-md-5">
                                      <div className="">
                                        <Select options={salesChannels} placeholder="Select an option"
                                          value={salesChannels.find(option => option.value === productChannel.accountPlatformId)}
                                          onChange={(e) =>
                                            handleChannelChange(e, i)
                                          }
                                        />
                                      </div>
                                    </div>
                                    {productChannel && productChannel.accountPlatformId &&
                                      <div className="col-md-5">
                                        <div className="">
                                          <CreatableSelect
                                            value={productChannel.productSourceIds?.map(opt => ({ value: opt, label: opt } as SelectOption))}
                                            isMulti
                                            placeholder="Paste product ids"
                                            onChange={(e) =>
                                              handleProductIdsChange(e, i)
                                            }
                                          />
                                        </div>
                                      </div>}
                                    <div className="col-md-2">
                                      <button
                                        onClick={(e) => deleteProductChannel(i)}
                                        type="button"
                                        className="btn btn-neutral btn-sm"
                                      >
                                        <i className="bi bi-trash"></i>
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              <div className="row g-5">
                                <div className="col-md-4">
                                  <button
                                    type="button"
                                    onClick={addProductChannel}
                                    className="btn btn-neutral btn-sm"
                                  >
                                    <i className="bi bi-plus pr-5"></i>Add product IDs

                                  </button>
                                </div>
                              </div>
                            </div>
                          </>}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-10 mt-0">
                  <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                    <h4 className="font-semibold mb-2">
                      Give away the following
                    </h4>
                    <p className="text-sm">
                      Choose what to give to your customer when they trigger the
                      automation with the criteria above
                    </p>
                  </div>
                  <div className="col-lg-8">
                    <div className="card shadow border-0">
                      <div className="card-body">
                        <div className="row g-5">

                          <div className="col-md-12">
                            <div className="">
                              <label
                                className="form-label"
                                htmlFor="awardAmountType"
                              >
                                Choose type of gift card you wish to create:
                              </label>
                              <div className="d-flex">
                                <div className="me-3">
                                  <Form.Check
                                    name="awardAmountType"
                                    type={"radio"}
                                    id={`awardAmountTypeFixed`}
                                    checked={
                                      model.awardAmountType ===
                                      AutomationAwardAmountType.FixedAmount
                                    }
                                    onChange={handleChange}
                                    value={
                                      AutomationAwardAmountType.FixedAmount
                                    }
                                  />
                                </div>
                                <div>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="awardAmountTypeFixed"
                                  >
                                    Gift card of fixed value
                                  </label>
                                </div>
                              </div>

                              {model.triggerType == AutomationTriggerType.OrderPaid &&
                                <div className="d-flex">
                                  <div className="me-3">
                                    <Form.Check
                                      name="awardAmountType"
                                      type={"radio"}
                                      id={`awardAmountTypeFixedEveryThreshold`}
                                      checked={
                                        model.awardAmountType ===
                                        AutomationAwardAmountType.FixedAmountEveryThreshold
                                      }
                                      onChange={handleChange}
                                      value={
                                        AutomationAwardAmountType.FixedAmountEveryThreshold
                                      }
                                    />
                                  </div>
                                  <div>
                                    <label
                                      className="form-label mb-0"
                                      htmlFor="awardAmountTypeFixedEveryThreshold"
                                    >
                                      Gift card of fixed value for every $x spent in one order
                                    </label>
                                  </div>
                                </div>
                              }
                              <div className="d-flex">
                                <div className="me-3">
                                  <Form.Check
                                    name="awardAmountType"
                                    type={"radio"}
                                    id={`awardAmountTypePercent`}
                                    checked={
                                      model.awardAmountType ===
                                      AutomationAwardAmountType.Percent
                                    }
                                    onChange={handleChange}
                                    value={AutomationAwardAmountType.Percent}
                                  />
                                </div>
                                <div>
                                  {model.triggerType == AutomationTriggerType.OrderPaid &&
                                    <label
                                      className="form-label mb-0"
                                      htmlFor="awardAmountTypePercent"
                                    >
                                      Gift card value as a percentage of order total
                                    </label>
                                  }
                                  {model.triggerType == AutomationTriggerType.GiftCardPurchased &&
                                    <label
                                      className="form-label mb-0"
                                      htmlFor="awardAmountTypePercent"
                                    >
                                      Gift card value as a percentage of purchased gift card value
                                    </label>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="">
                            {model.awardAmountType ===
                              AutomationAwardAmountType.FixedAmount && (
                                <div className="col-md-4">
                                  <div className="input-group input-group-inline">
                                    <span className="input-group-text" id="">
                                      $
                                    </span>
                                    <Form.Control
                                      name={"awardAmount"}
                                      className="form-control"
                                      type="number"
                                      value={model.awardAmount}
                                      onChange={handleChange}
                                      placeholder="0.00"
                                      aria-label="0.00"
                                      required
                                    />
                                    <span className="input-group-text" id="">
                                      {authContext.account?.isoCurrencyCode}
                                    </span>
                                  </div>
                                  <span className="mt-2 valid-feedback">
                                    Looks good!
                                  </span>
                                </div>
                              )}
                            {model.awardAmountType ===
                              AutomationAwardAmountType.FixedAmountEveryThreshold && (
                                <div className="row row-cols-lg-auto g-3 align-items-center">
                                  <div className="">
                                    <div className="input-group input-group-inline">
                                      <span className="input-group-text" id="">
                                        $
                                      </span>
                                      <Form.Control
                                        name={"awardAmount"}
                                        className="form-control"
                                        type="number"
                                        value={model.awardAmount}
                                        onChange={handleChange}
                                        placeholder="0.00"
                                        aria-label="0.00"
                                        required
                                      />
                                      <span className="input-group-text" id="">
                                        {authContext.account?.isoCurrencyCode}
                                      </span>
                                    </div>
                                    <span className="mt-2 valid-feedback">
                                      Looks good!
                                    </span>
                                  </div>
                                  <span>for every</span>
                                  <div className="">
                                    <div className="input-group input-group-inline">
                                      <span className="input-group-text" id="">
                                        $
                                      </span>
                                      <Form.Control
                                        name={"awardEveryThresholdAmount"}
                                        className="form-control"
                                        type="number"
                                        value={model.awardEveryThresholdAmount}
                                        onChange={handleChange}
                                        placeholder="0.00"
                                        aria-label="0.00"
                                        required
                                      />
                                      <span className="input-group-text" id="">
                                        {authContext.account?.isoCurrencyCode}
                                      </span>
                                    </div>
                                    <span className="mt-2 valid-feedback">
                                      Looks good!
                                    </span>
                                  </div>
                                  <span>spent</span>
                                </div>
                              )}
                            {model.awardAmountType ===
                              AutomationAwardAmountType.Percent && (
                                <div className="col-md-4">
                                  <div className="input-group input-group-inline">
                                    <Form.Control
                                      name={"awardAmount"}
                                      className="form-control"
                                      type="number"
                                      value={model.awardAmount}
                                      onChange={handleChange}
                                      placeholder="0.00"
                                      aria-label="0.00"
                                      required
                                    />
                                    <span className="input-group-text" id="">
                                      %
                                    </span>
                                  </div>
                                  <span className="mt-2 valid-feedback">
                                    Looks good!
                                  </span>
                                </div>
                              )}
                          </div>




                          <div className="col-md-12">
                            <div className="">
                              <label
                                className="form-label"
                                htmlFor="awardAmountType"
                              >
                                Choose gift card expiry:
                              </label>
                              <div className="d-flex">
                                <div className="me-3">
                                  <Form.Check
                                    name="expiryType"
                                    type={"radio"}
                                    id={`expiryTypeNone`}
                                    checked={
                                      model.expiryType ===
                                      AutomationGiftCardExpiryType.None
                                    }
                                    onChange={handleChange}
                                    value={
                                      AutomationGiftCardExpiryType.None
                                    }
                                  />
                                </div>
                                <div>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="expiryTypeNone"
                                  >
                                    No expiry date
                                  </label>
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="me-3">
                                  <Form.Check
                                    name="expiryType"
                                    type={"radio"}
                                    id={`expiryTypeFixedDate`}
                                    checked={
                                      model.expiryType ===
                                      AutomationGiftCardExpiryType.FixedDate
                                    }
                                    onChange={handleChange}
                                    value={
                                      AutomationGiftCardExpiryType.FixedDate
                                    }
                                  />
                                </div>
                                <div>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="expiryTypeFixedDate"
                                  >
                                    Fixed date expiry
                                  </label>
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="me-3">
                                  <Form.Check
                                    name="expiryType"
                                    type={"radio"}
                                    id={`expiryTypeDaysSinceTrigger`}
                                    checked={
                                      model.expiryType ===
                                      AutomationGiftCardExpiryType.DaysSinceTrigger
                                    }
                                    onChange={handleChange}
                                    value={AutomationGiftCardExpiryType.DaysSinceTrigger}
                                  />
                                </div>
                                <div>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="expiryTypeDaysSinceTrigger"
                                  >
                                    Expires x days after order date
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="">
                            {model.expiryType ===
                              AutomationGiftCardExpiryType.FixedDate && (
                                <div className="col-md-4">
                                  <span>Expires on</span>
                                  <div className="input-group input-group-inline">
                                    <Form.Control
                                      name={"expiresAt"}
                                      className="form-control"
                                      type="date"
                                      value={dayjs(model.expiresAt!).format('YYYY-MM-DD')}
                                      onChange={handleChange}
                                      required

                                    />

                                  </div>
                                  <span className="mt-2 valid-feedback">
                                    Looks good!
                                  </span>
                                </div>
                              )}
                            {model.expiryType ===
                              AutomationGiftCardExpiryType.DaysSinceTrigger && (
                                <div className="row row-cols-lg-auto g-3 align-items-center">
                                  <div className="">
                                    <div className="input-group input-group-inline">
                                      <Form.Control
                                        name={"expiresInNumDays"}
                                        className="form-control"
                                        type="number"
                                        value={model.expiresInNumDays}
                                        onChange={handleChange}
                                        placeholder="0"
                                        aria-label="0"
                                        required
                                      />
                                    </div>
                                    <span className="mt-2 valid-feedback">
                                      Looks good!
                                    </span>
                                  </div>
                                  <span>days after order date</span>
                                </div>
                              )}
                          </div>



                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-10 mt-0">
                  <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                    <h4 className="font-semibold mb-2">Limitations</h4>
                    <p className="text-sm">
                      Specify some dates to run this automation or limit the
                      number of uses
                    </p>
                  </div>
                  <div className="col-lg-8">
                    <div className="card shadow border-0">
                      <div className="card-body">
                        <div className="row g-5">
                          <div className="col-md-6">
                            <div className="d-flex align-items-center mb-2">
                              <label
                                className="form-label mb-0"
                                htmlFor="startsAt"
                              >
                                When does this automation begin?
                              </label>
                              <div className="ms-auto">
                                <span className="text-sm text-muted">
                                  Optional
                                </span>
                              </div>
                            </div>

                            <Form.Control
                              name={"startsAt"}
                              className="form-control"
                              type="datetime-local"
                              placeholder="Now"
                              value={startsAt}
                              onChange={handleChange}
                            />
                            <span className="d-block mt-2 text-sm text-muted">
                              Leave blank for starts now
                            </span>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex align-items-center mb-2">
                              <label
                                className="form-label mb-0"
                                htmlFor="endsAt"
                              >
                                When does this automation end?
                              </label>
                              <div className="ms-auto">
                                <span className="text-sm text-muted">
                                  Optional
                                </span>
                              </div>
                            </div>
                            <Form.Control
                              name={"endsAt"}
                              className="form-control"
                              type="datetime-local"
                              placeholder="Never ends"
                              value={endsAt}
                              onChange={handleChange}
                            />
                            <span className="d-block mt-2 text-sm text-muted">
                              Leave blank for never ends
                            </span>
                          </div>
                          <hr className="my-6" />
                          <div className="col-md-12 mt-0">
                            <div className="d-flex ">
                              <div className="me-3">
                                <Form.Check
                                  name="shouldLimitMaxUsage"
                                  id="shouldLimitMaxUsage"
                                  type={"checkbox"}
                                  checked={model.shouldLimitMaxUsage}
                                  onChange={handleChange}
                                />
                              </div>
                              <div>
                                <label
                                  className="form-label mb-0"
                                  htmlFor="shouldLimitMaxUsage"
                                >
                                  Require a max usage limit?
                                </label>
                              </div>
                            </div>
                            {model.shouldLimitMaxUsage && (
                              <div className="">
                                <label
                                  className="form-label"
                                  htmlFor="maxUsageLimit"
                                >
                                  Max number of uses
                                </label>
                                <p className="text-sm text-muted d-none d-lg-block pb-5">
                                  This is NOT a per-customer count, it is a
                                  TOTAL count of all orders/gift cards triggered
                                  using this automation. If threshold is
                                  reached, the automation will be ended
                                  automatically.
                                </p>
                                <div className="col-md-4">
                                  <Form.Control
                                    name={"maxUsageLimit"}
                                    className="form-control "
                                    type="number"
                                    value={model.maxUsageLimit}
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-10 mt-0">
                  <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                    <h4 className="font-semibold mb-2">Email notification</h4>
                    <p className="text-sm">
                      Customize the gift card email you'd like to send
                    </p>
                  </div>
                  <div className="col-lg-8">
                    <div className="card shadow border-0">
                      <div className="card-body">
                        <div className="row g-5">
                          <div className="col-md-12">
                            <div className="d-flex ">
                              <div className="me-3">
                                <Form.Check
                                  name="shouldSendEmail"
                                  id="shouldSendEmail"
                                  type={"checkbox"}
                                  checked={model.shouldSendEmail}
                                  onChange={handleChange}
                                />
                              </div>
                              <div>
                                <label
                                  className="form-label mb-0"
                                  htmlFor="shouldSendEmail"
                                >
                                  Send email notification?
                                </label>
                              </div>
                            </div>
                            {model.shouldSendEmail && (
                              <div className="list-group mt-5">
                                <div className="list-group-item d-flex align-items-center shadow-1 border rounded">
                                  <div className="flex-fill pl-20">
                                    <a onClick={(event) => saveAndNavigateToEditEmail(event)}
                                      href={`/automations/design-email/${id}`}
                                      className="d-block h6 font-semibold mb-1"
                                    >
                                      Subject: {model.emailSubject}
                                    </a>
                                    <span className="d-block text-sm mb-2">
                                      Sent to customer when a gift card is
                                      issued.
                                    </span>
                                    <div className="d-flex mx-n1"></div>
                                  </div>
                                  <div className="ms-auto text-end">
                                    <a onClick={(event) => saveAndNavigateToEditEmail(event)}
                                      href={`/automations/design-email/${id}`}
                                      className="btn btn-sm btn-neutral mr-10"
                                    >
                                      Preview &amp; edit
                                    </a>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row mb-10 mt-0">
                  <hr className="mb-7" />
                  <div className="text-end mb-5 mb-lg-0 pe-lg-16">
                    <button type="submit" className="btn btn-sm btn-primary">
                      Save automation
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </Form>
        </>
      )}
    </React.Fragment>
  );
};

export default AutomationEditPage;
