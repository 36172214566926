import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Button, Row, Modal, Form, Container, Col, FloatingLabel } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { AssetApi, GiftcardApi } from "../../apiclient";
import { apiConfig } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import "./PublicGiftToFriend.css";
import {
  GiftToFriendPageSettingsViewModel,
  AddToCartSendGiftCardToFriendViewModel,
  GiftCardImageAssetViewModel,
} from "../../apiclient/models";
import TimezoneSelect from "react-timezone-select";
import { Helmet } from "react-helmet";
import PoweredByFooter from "../../components/PoweredByFooter";

const PublicAddToCartPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  let { id } = useParams();
  const [isLoading, setLoading] = useState(false as boolean);
  const [model, setModel] = useState({} as AddToCartSendGiftCardToFriendViewModel);
  const [themeModel, setThemeModel] = useState(
    {} as GiftToFriendPageSettingsViewModel
  );
  const [giftMessage, setGiftMessage] = useState("");
  const [customHeadScript, setCustomHeadScript] = useState("");
  const [validated, setValidated] = useState(false);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [accountId, setAccountId] = useState("");
  let assetApi = new AssetApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  let cardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  const [images, setImages] = useState([] as GiftCardImageAssetViewModel[]);
  const [selectedImage, setSelectedImage] = useState(
    {} as GiftCardImageAssetViewModel
  );

  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768); // Set threshold for mobile devices (768px)
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    checkMobile(); // Check on component mount

    // Add event listener to check on window resize
    window.addEventListener('resize', checkMobile);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  useEffect(() => {
    if (images.length > 0) {
      setSelectedImage(images[0]);
    }
  }, [images]);

  const timezoneOnChange = (selectedTimezone: any) => {
    console.log("selectedTimezone", selectedTimezone);
    setTimezone(selectedTimezone.value);
  };

  const fetchThemeData = (aId: string) => {
    assetApi
      .assetGiftToFriendPagePublicIdGet(aId!)
      .then((response) => {
        var data = response.data;
        setThemeModel(data);

      })
      .catch((error: any) => { });
  };

  const fetchCustomScript = (aId: string) => {
    assetApi
      .assetGetCustomEmbedScriptByTypeGet(aId!, "GiftToFriendPopupHeadScript")
      .then((response) => {
        var data = response.data;
        if (data) {
          setCustomHeadScript(data!);
        }
      })
      .catch((error: any) => { });
  };

  const fetchAssetImages = (aId: string) => {
    assetApi
      .assetActiveImageAssetsIdGet(aId!)
      .then((response) => {
        var data = response.data;

        setImages(data);
        setLoading(false);
      })
      .catch((error: any) => {
        console.log("get survey error", error);
        setLoading(false);
      });
  };

  const getLocalDate = (dateStr: string) => {
    const currentDateTime = new Date(dateStr);
    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, "0");
    const day = String(currentDateTime.getDate()).padStart(2, "0");
    const hours = String(currentDateTime.getHours()).padStart(2, "0");
    const minutes = String(currentDateTime.getMinutes()).padStart(2, "0");

    var localDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return localDate;
  };

  const fetchData = () => {
    cardApi
      .giftcardAddToCartGiftCardToFriendIdGet(id!)
      .then((response) => {
        var data = response.data;
        if (data) {
          data.sendLater = false;
          data.giftMessage = "Enjoy your gift!";
          data.giftScheduledFor = getLocalDate(new Date().toString());
        }
        setModel(data);
        setGiftMessage(data.giftMessage!);
        fetchThemeData(data.accountId!);
        fetchAssetImages(data.accountId!);
        fetchCustomScript(data.accountId!);
        setAccountId(data.accountId!);
      })
      .catch((error: any) => { })
      .finally(() => {
        setLoading(false);
      });

    // setModel({
    //   recipientEmailAddress:"",
    //   giftedFromName:"",
    //   giftImageUrl:"",
    //   recipientName:"",
    //   sendLater: false,
    //   giftMessage:"Enjoy your gift!",
    //   giftScheduledFor: getLocalDate(new Date().toString()),
    // });

    // fetchThemeData(id!);
    // fetchAssetImages(id!);
    // fetchCustomScript(id!);
  };

  const handleChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = event.target.name;
    if (name === "sendLater") {
      const booVal = event.currentTarget.value === "true" ? true : false;
      value = booVal;
    }

    if (name === "giftMessage") {
      setGiftMessage(value);
    }
    setModel((values: AddToCartSendGiftCardToFriendViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      model.giftMessage = giftMessage;
      model.timezone = timezone;
      if (selectedImage) {
        model.giftImageUrl = selectedImage.imageUrl;
      }

      var gc_properties = {} as any;
      gc_properties["Type"] = "Gift to friend";
      gc_properties["From name"] = model.giftedFromName;
      gc_properties["Recipient name"] = model.recipientName;
      gc_properties["Recipient email"] = model.recipientEmailAddress;
      gc_properties["Gift message"] = model.giftMessage;
      if (selectedImage) {
        gc_properties["Image"] = selectedImage.imageUrl?.replace("https://s3.amazonaws.com/giftkard.uploads/", "");
      }
      if (model.sendLater) {
        gc_properties["Send later at"] = model.giftScheduledFor;
        gc_properties["Timezone"] = timezone;
      }
      var customAttributes = {
        id: "wrapped",
        details: gc_properties
      };

      // Pass data to parent window
      window.parent.postMessage(customAttributes, '*');
      fetchData(); //clear inputs
    }
  };

  const handleImageSelect = (image: GiftCardImageAssetViewModel) => {
    setSelectedImage(image);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <LoaderIcon />
        </>
      ) : (
        <div className="">
          <div
            className=""
          >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="p-5">
                <div className=" pb-10">
                  <div className="row mb-10">
                    <div className="mx-auto">
                      <Row>
                        <div className="col-md-6">

                          <div
                            className="card mb-5"
                          >
                            <div className="card-body">
                              <Row>
                                <Form.Group className="">
                                  <FloatingLabel
                                    label={themeModel.giftCardFromNameFormLabel}
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      name="giftedFromName"
                                      value={model.giftedFromName || ""}
                                      onChange={handleChange}
                                      required
                                    />
                                  </FloatingLabel>

                                </Form.Group>
                                <Form.Group className="mt-5">
                                  <FloatingLabel
                                    label={themeModel.giftCardFriendNameFormLabel}
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      name="recipientName"
                                      value={model.recipientName || ""}
                                      onChange={handleChange}
                                      required
                                    />
                                  </FloatingLabel>

                                </Form.Group>
                                <Form.Group className="mt-5">
                                  <FloatingLabel
                                    label={themeModel.giftCardFriendEmailFormLabel}
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      name="recipientEmailAddress"
                                      value={model.recipientEmailAddress || ""}
                                      onChange={handleChange}
                                      required
                                    />
                                  </FloatingLabel>
                                </Form.Group>
                                <Form.Group className="mt-5">
                                  <div
                                    className="btn-group w-full"
                                    role="group"
                                    aria-label="Basic radio toggle button group"
                                  >
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="sendLater"
                                      id="btnradio1"
                                      value="false"
                                      checked={model.sendLater === false}
                                      onChange={handleChange}
                                    />
                                    <label
                                      className="btn btn-outline-primary btn-sm"
                                      htmlFor="btnradio1"
                                    >
                                      {themeModel.giftCardSendNowButtonText}
                                    </label>

                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="sendLater"
                                      id="btnradio2"
                                      value="true"
                                      checked={model.sendLater === true}
                                      onChange={handleChange}
                                    />
                                    <label
                                      className="btn btn-outline-primary btn-sm"
                                      htmlFor="btnradio2"
                                    >
                                      {themeModel.giftCardSendLaterButtonText}
                                    </label>
                                  </div>
                                </Form.Group>
                                {model.sendLater && (
                                  <>
                                    <Form.Group className="mt-5">
                                      <FloatingLabel
                                        label={themeModel.giftCardSendAtFormLabel}
                                        className="mb-3"
                                      >
                                        <Form.Control
                                          name="giftScheduledFor"
                                          type="datetime-local"
                                          value={model.giftScheduledFor || ""}
                                          onChange={handleChange}
                                          required
                                        />
                                      </FloatingLabel>

                                    </Form.Group>
                                    <Form.Group className="mt-5">
                                      <Form.Label
                                      >
                                        {themeModel.giftCardTimezoneFormLabel}
                                      </Form.Label>
                                      <TimezoneSelect
                                        value={timezone}
                                        onChange={timezoneOnChange}
                                      />
                                    </Form.Group>
                                  </>
                                )}
                              </Row>


                            </div>
                          </div>
                          {themeModel && !themeModel.hideWrappedBranding && !isMobile && (
                           <div className="mt-10">
                            <PoweredByFooter />
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          {images.length > 1 && (
                            <div
                              className="card mb-5"
                            >
                              <Row>
                                {images.map((image) => (
                                  <Col key={image.id} xs={6} sm={6} md={3}>
                                    <label
                                      className={`radio-image ${selectedImage.id === image.id
                                        ? "selected"
                                        : ""
                                        }`}
                                      onClick={() => handleImageSelect(image)}
                                    >
                                      <img
                                        src={image.imageUrl}
                                        alt={`Image ${image.id}`}
                                      />
                                    </label>
                                  </Col>
                                ))}
                              </Row>
                            </div>
                          )}
                          <div
                            className="card mb-10"
                          >
                            <div className="card-body">
                              <Row>
                                <Form.Group className="mt-5">
                                  <FloatingLabel
                                    label={themeModel.giftCardMessageFormLabel}
                                    className="mb-3"
                                  >
                                    <Form.Control
                                      as="textarea"
                                      rows={4}
                                      style={{ minHeight: "100px" }}
                                      placeholder="e.g. Happy birthday"
                                      name="giftMessage"
                                      value={model.giftMessage || ""}
                                      onChange={handleChange}
                                    />
                                  </FloatingLabel>
                                </Form.Group>
                              </Row>

                            </div>
                          </div>
                          <div className="mt-5">
                            <button
                              className="btn btn-primary w-full btn-custom"
                              type="submit"
                            >
                              Add to cart
                            </button>
                          </div>
                          {themeModel && !themeModel.hideWrappedBranding && isMobile && (
                            <div className="mt-10">
                            <PoweredByFooter />
                            </div>
                          )}
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>

        </div>
      )}
      <Helmet>
        {accountId && (
          <link rel="stylesheet" href={`Asset/gifttofriendstyle.css?accountId=${accountId}`} />
        )}
        {customHeadScript && (
          <script>{`
            document.head.innerHTML += \`${customHeadScript}\`;
          `}</script>
        )}
      </Helmet>
    </React.Fragment>
  );
};

export default PublicAddToCartPage;
